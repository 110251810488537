import React, { Component } from 'react';
import SearchDealer from './search-dealer';
import { Redirect } from 'react-router-dom';
import SearchResult from './Manageresult';
import secureStorage from './../../../config/encrypt';
import DateFormate from 'dateformat';
import { connect } from 'react-redux';
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';
import { CSVLink } from "react-csv";

class ManageDealerList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            pageloading:true,
            dealerListData: [],
            searchByList: [],
            cityAllList: [],
            dealerPremiumTypeList: [],
            dealershipCategoryList:[],
            dcSkusList: [],
            dealerCountStatusDetail: {},
            page: 0,
            filterData: {
                status:'1'
            },
            mappingCsvData:[],
            redirect: false,
            dealer_onboarding_type: []
        };
        this.onScroll = this.onScroll.bind(this);
        this.getDealerList = this.getDealerList.bind(this);
        this.flag = true;
        this.csvLink = React.createRef();

    }
    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            this.afterLoginSetState(loginResponse)
        } else {
            this.setState({ redirect: true })
        }
    }
    componentDidMount = async () => {
        await this.updateReduxData();
        this.onScroll('Mounter');
        window.addEventListener('scroll', this.onScroll);
        await this.getSearchByList();
        await this.getCityList();
        await this.getDealerSkus();
        await this.getDealerCountStatusDetail();
        await this.getDealerPremiumTypeList();
        this.getDealerOnboardingType();

    }
    afterLoginSetState = (loginResponse) => {
        // let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;//delet
        let role = loginResponse?.data?.user_data?.role || null;

        if (role != "admin") {
            this.setState({ redirect: '/' })
        }
    }
    handleOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.value) != null ? oValue.value : oValue.target.value;
        this.setState({
            filterData
        });
    }
    handleCityOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.id) != null ? oValue.id : oValue.target.id;
        this.setState({
            filterData
        });
    }
    handleSkuOptionChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.sku_short_name) != null ? oValue.sku_short_name : oValue.target.sku_short_name;
        this.setState({
            filterData
        });
    }

    handlePremiumChange = (sname, oValue) => {
        let filterData = this.state.filterData;
        filterData[sname] = (oValue.code) != null ? oValue.code : oValue.target.code;
        this.setState({
            filterData
        });
    }
    
    handleStatusClick = async (sname, ovalue) => {
        let filterData = this.state.filterData;
        if (sname == 'active') {
            filterData['status'] = '1';
        } else if (sname == 'inactive') {
            filterData['status'] = '2';
        }else if (sname == 'incomplete') {
            filterData['status'] = '3';
        }else if (sname == 'pending') {
            filterData['status'] = '4';
        }else if (sname == 'blacklist') {
            filterData['status'] = '5';
        }else if (sname == 'reject') {
            filterData['status'] = '6';
        }  else if (sname == 'all') {
            filterData['status'] = '';
        }
        this.setState({ filterData });
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.page=1;
        // this.setState({page: 1})
        this.getDealerList();
        this.getDealerCountStatusDetail();
    }
    onScroll(event) {
        if ((window.innerHeight + window.scrollY > document.body.offsetHeight - 300)) {
            if (this.flag) {
                let current_page = this.state.page;
                let loading=false,pageloading=false;
                if(current_page==0) {
                    pageloading = true;
                }else{
                    loading = true;
                }
                this.setState({ page: current_page + 1,loading:loading,pageloading:pageloading}, () => {
                    this.getDealerList();
                });
            }
            this.flag = false;
        }
    }

    getDealerList() {
        
        var thisObj = this;
        //this.setState({loading:true})
        MasterService.post('dealer/dealer/dealer-list', { 'page_no': this.state.page, ...this.state.filterData })
            .then(function (response) {
                thisObj.flag = false;
                thisObj.setState({loading:false,pageloading:false})
                if (response.data.status == 200) {
                    if (response.data.data.length) {
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ dealerListData: response.data.data ,loading:false,pageloading:false}, () => {
                                thisObj.flag = true;
                            });
                        }
                        else {
                            thisObj.setState({
                                dealerListData: thisObj.state.dealerListData.concat(response.data.data)
                            ,loading:false,pageloading:false}, () => {
                                thisObj.flag = true;
                            });
                        }
                    } else {
                        if (thisObj.state.page <= 1) {
                            thisObj.setState({ dealerListData: response.data.data});
                        }
                    }
                }
            })
            .catch(function (response) {
                thisObj.setState({loading:false,pageloading:false})
            });
    }
    submitFilterForm = () => {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.page=1;
        // this.setState({page: 1})
        this.setState({pageloading:true})
        this.getDealerList();
        this.getDealerCountStatusDetail();
    }
    getSearchByList() {
        // var parentObj = this;
        // var thisObj = this;
        MasterService.get('dealer/dealer/search-by-list')
            .then((searchResult) => {
                this.flag = false;
                if (searchResult && searchResult.data && searchResult.data.status == 200 && searchResult.data.data) {
                    this.setState({
                        searchByList: searchResult.data.data
                    })
                }
            })
            .catch(function (searchError) {
                console.error("searchError", searchError)
            });
    }
    getDealerPremiumTypeList() {
        var parentObj = this;
        var thisObj = this;
        MasterService.get('core/commonservice/master/?master[]=dealer_premium_type')
            .then(function (response) {
                thisObj.flag = false;
                if (response.data.status == 200) {
                    parentObj.setState({
                        dealerPremiumTypeList: response.data.data.dealer_premium_type,
                    })
                }
            })
            .catch(function (response) {
            });
    }
    getDealershipCategoryList() {
        var parentObj = this;
        var thisObj = this;
        MasterService.get('core/commonservice/master/?master[]=dealership_category')
            .then(function (response) {
                thisObj.flag = false;
                if (response.data.status == 200) {
                    parentObj.setState({
                        dealershipCategoryList: response.data.data.dealership_category,
                    })
                }
            })
            .catch(function (response) {
            });
    }
    getCityList() {
        var parentObj = this;
        
        var thisObj = this;
        MasterService.get('core/commonservice/state_city_all')
            .then(function (response) {
                // console.log(response);return false;
                thisObj.flag = false;
                if (response.data.status == 200) {
                    parentObj.setState({
                        cityAllList: response.data.data.city
                    })
                }
            })
            .catch(function (response) {
            });
    }
    getDealerSkus() {
        var parentObj = this;
        // const headers = {//deleteme
        //     'Content-Type': 'application/json',
        //     'Authorization': this.props.auth_token,
        //     'Accept-Language': 'en'
        // }
        var thisObj = this;
        MasterService.get('core/commonservice/master/?master[]=dealer_sku')
            .then(function (response) {
                // console.log(response);return false;
                thisObj.flag = false;
                if (response.status == 200 && response.data.status == 200) {
                    parentObj.setState({
                        dcSkusList: response.data.data.dealer_sku || []
                    })
                }
            })
            .catch(function (response) {
            });
    }
    getDealerCountStatusDetail() {
        var parentObj = this;
        // const headers = {//deleteme
        //     'Content-Type': 'application/json',
        //     'Authorization': this.props.auth_token,
        //     'Accept-Language': 'en'
        // }
        // var thisObj = this;//deleteme
        MasterService.post('dealer/dealer/count_dealer_status', { 'page_no': this.state.page, ...this.state.filterData })
            .then(function (response) {
                console.log(response);
                if (response.status == 200 && response.data.status == 200) {
                    parentObj.setState({
                        dealerCountStatusDetail: response.data.data || {}
                    },()=>{
                        console.log(parentObj.state.dealerCountStatusDetail)
                    })
                }
            })
            .catch(function (response) {
            });
    }

    submitExportData = () => {
        console.log("submitExportData")
        this.setState({pageloading:true})
        this.getExportDataList();
    }

    getExportDataList() {
        var thisObj = this;
        // let status = this.state.filterData.status//deleteme
        //this.setState({loading:true})
        MasterService.post('dealer/dealer/get_export_data', { 'page_no': this.state.page, ...this.state.filterData })
            .then(function (response) {
                thisObj.flag = false;
                thisObj.setState({ loading: false, pageloading: false })
                if (response.data.status == 200) {
                    let csv_object = []
                    csv_object = response.data.data.map(elm => ({
                        "Dealership_Code": elm.gcd_code ? elm.gcd_code : "",
                        "Dealeship Name": elm.organization ? elm.organization : "",
                        "Dealer_Owner_Name": elm.owner_name ? elm.owner_name : "",
                        "E-mail": elm.dealership_email ? elm.dealership_email : "",
                        "Contact_No": elm.dealership_contact ? elm.dealership_contact : "",
                        "Address": elm.address ? elm.address : "",
                        "Latitude": elm.latitude ? elm.latitude : "",
                        "Longtitude": elm.longitude ? elm.longitude : "",
                        "Barangay": elm.localityname ? elm.localityname : "",
                        "City": elm.cityname ? elm.cityname : "",
                        "Region": elm.statename ? elm.statename : "",
                        "Dealer Size": elm.dealer_size ? elm.dealer_size : "",
                        "Dealership Category": elm.dealership_category ? elm.dealership_category : "",
                        "Dealer Account Creation Date": elm.created_date ? DateFormate(elm.created_date,"yyyy-mm-dd") : "",
                        "Parking Space": elm.parking_space,
                        "Inventory Size": elm.inventory_size,
                        "Average Monthly Sales": elm.average_monthly_sales,
                        "Potential Sales": elm.potential_sales,
                        "Onboarded Through": elm.onboarded_through
                    }));
                    thisObj.setState({ mappingCsvData: csv_object }, () => {
                        if (thisObj.csvLink.current && thisObj.csvLink.current.link) thisObj.csvLink.current.link.click();
                    })
                }
            })
            .catch(function (error) {
                console.log("error", error)
                thisObj.setState({ loading: false, pageloading: false })
            });
    }

    getDealerOnboardingType() {
        MasterService.post("/dealer/dealer/get_onboarding_type", {})
            .then((resp) => {
                if (resp?.status === 200) {
                    this.setState({ dealer_onboarding_type: resp?.data?.data || [] })
                } else {
                    console.log("getDealerOnboardingType: ", resp.message);
                }
            })
            .catch((error) => {
                console.log("Error in getDealerOnboardingType: ", error);
            })
    }

  
    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/login'} />);
        }
        return (
            <div className={(this.state.pageloading) ? "container-fluid loading":"container-fluid"}>
                <div className="addStock">
                    <h1>{this.props.t('Listing.Dealer_Listing')}</h1>
                    <SearchDealer dealerPremiumTypeList={this.state.dealerPremiumTypeList} dealershipCategoryList={this.state.dealershipCategoryList} dcSkusList={this.state.dcSkusList} cityAllList={this.state.cityAllList} searchByList={this.state.searchByList} optionChange={this.handleOptionChange} optionCityChange={this.handleCityOptionChange} optionPremiumChange={this.handlePremiumChange} optionSkuChange={this.handleSkuOptionChange} onSubmitFilter={this.submitFilterForm} dealer_onboarding_type={this.state.dealer_onboarding_type} />
                    <div className="clearfix">
                        <SearchResult pageloading={this.state.pageloading} loading={this.state.loading} dealerListData={this.state.dealerListData} statusClick={this.handleStatusClick} dealerCountStatusDetail={this.state.dealerCountStatusDetail} filterData={this.state.filterData}  onExportData={this.submitExportData} dealer_onboarding_type={this.state.dealer_onboarding_type} />
                        <CSVLink
                            asyncOnClick={true}
                            className="hidden"
                            ref={this.csvLink}
                            data={this.state.mappingCsvData ? this.state.mappingCsvData : []}
                            filename={`dealer-list-${Math.round(+new Date() / 1000)}.csv`}
                            data-interception='off'
                        >
                            {this.props.t('Listing.Search_Result.Export_Data')}
                        </CSVLink>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(ManageDealerList));
//export default connect(mapStateToProps, mapDispatchToProps)(ManageDealerList);
//export default ManageDealerList;
