import React, { Component } from 'react';
import { Link, NavLink, Redirect,withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ModalPopup from '../../elements/Popup';
import MasterService from './../../../service/MasterService';
import BlackListPopup from './BlackListPopup';
import {ALLOW_AUCTION, SFA_ROLE_LIST} from '../../../config/constant';
import ViewHistory from '../../dealerDetail/component/ViewHistory';

class SearchResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dealerListData: props.dealerListData,
            loading: false,
            pageloading: false,
            statusClassname: {
                'active': 'nav-item active',
                'inactive': 'nav-item',
                'incomplete': 'nav-item',
                'blacklist': 'nav-item',
                'pending': 'nav-item',
                'all': 'nav-item',
                'reject': 'nav-item'
            },
            filterData: {
            },
            dealerUpdateData: {
            },
            blackListPopup: false,
            blackListUpdateData: {},
            currentIndex: null,
            current_org_name: null,
            auctionModal: false,
            selectedUserType:0,
            redirect: false,
            role_list: SFA_ROLE_LIST,
            history_popup : false,
            histoy_dealer_id : 0,
        };

    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            dealerListData: nextProps.dealerListData,
            loading: nextProps.loading,
            pageloading: nextProps.pageloading
        });
    }
    handleStatusClick = (sname, ovalue) => {
        let filterData = this.state.filterData;
        if (sname === 'active') {
            filterData['status'] = 1;
        } else if (sname === 'inactive') {
            filterData['status'] = 2;
        } else if (sname === 'incomplete') {
            filterData['status'] = 3;
        } else if (sname === 'pending') {
            filterData['status'] = 4;
        } else if (sname === 'blacklist') {
            filterData['status'] = 5;
        } else if (sname === 'reject') {
            filterData['status'] = 6;
        }else if (sname === 'all') {
            filterData['status'] = null;
        }
        this.setState({ filterData });
        if (typeof this.props.statusClick === 'function') {
            this.props.statusClick(sname, ovalue);
            this.setState({
                statusClassname: {
                    'active': 'nav-item',
                    'inactive': 'nav-item',
                    'incomplete': 'nav-item',
                    'blacklist': 'nav-item',
                    'pending': 'nav-item',
                    'reject': 'nav-item',
                    'all': 'nav-item',
                    [sname]: 'nav-item active'
                }
            })
        }
    }
    handleChange = (idx, dealer_id_hash,current_status) => async ({ target }) => {
        let org = this.state.dealerListData[idx] || {}
        let active = target.checked ? "Active" : "Inactive";
        var r = window.confirm(this.props.t('Listing.Search_Result.alert_active_inactive',{ status: active,dealer_name:org.organization }));
        let dealerUpdateData = this.state.dealerUpdateData;
        let redirect_url = '';
        if (r == true) {
            if (target.checked) {
                dealerUpdateData['status'] = '1';
                dealerUpdateData['current_status'] = current_status;
                dealerUpdateData['dealer_id_hash'] = dealer_id_hash;

            } else {
                dealerUpdateData['status'] = '2';
                dealerUpdateData['current_status'] = current_status;
                dealerUpdateData['dealer_id_hash'] = dealer_id_hash;
            }
            this.setState({ dealerUpdateData });
            let thisObj = this;
            let dealerListTempData = [...this.state.dealerListData];
            let checkVal = target.checked;
            // if(dealer_id_hash){
            //     this.setState({
            //         loading: true,
            //     });
            //     let validation_status = await DealerService.validateDealer(dealer_id_hash, org.id);
            //     if(validation_status.basic_details){
            //         redirect_url = `/dealer/${dealer_id_hash}/basic-details`
            //     }else if(validation_status.dealership_details){
            //         redirect_url = `/dealer/${dealer_id_hash}/outlet-info`
            //     }

            //     if(redirect_url){
            //         this.props.history.push(redirect_url);
            //     }
            //     this.setState({
            //         loading: false,
            //     });
            // }
            if(!redirect_url){
                this.setState({
                    loading: true,
                });
            const response = await MasterService.post('dealer/dealer/status_change', { ...this.state.dealerUpdateData });
            thisObj.flag = false;
            if (response.status == 200) {
                if (checkVal) {
                    target.parentNode.style.textDecoration = "";
                    dealerListTempData[idx].status = 1;
                } else {
                    target.parentNode.style.textDecoration = "line-through";
                    dealerListTempData[idx].status = 2;
                }
                thisObj.setState({ dealerListData: dealerListTempData });
                this.setState({
                    loading: false,
                });
                
                if(active == "Active"){
                this.handleStatusClick('active');
                }else if(active == "Inactive"){
                this.handleStatusClick('inactive');
                }
            }
        }


        }
    }
    handleBlackList = (idx, dealer_id_hash, status) => async ({ target }) => {
        let tempBlackListUpdateData = { ...this.state.blackListUpdateData };
        tempBlackListUpdateData.status = status;
        tempBlackListUpdateData.dealer_id_hash = dealer_id_hash;
        let org_name = this.state.dealerListData[idx] && this.state.dealerListData[idx].organization
        this.setState({ blackListPopup: true, blackListUpdateData: tempBlackListUpdateData, currentIndex: idx, current_org_name: org_name }, () => { });
    }
    // auctionPopup = () => {        
    //     this.setState({ auctionModal: true},()=>{});
    // }

    closeModal = () => {
        this.setState({ blackListPopup: false });
        //this.setState({ auctionModal: false });
    }
    updateDealerListData = (updatedObj, index) => {
        let dealerListTempData = [...this.state.dealerListData];
        dealerListTempData[index].status = updatedObj.status;
        this.setState({ dealerListData: dealerListTempData });
    }
    // submitAuctionUserForm = async(arg) => {   
    //     this.setState({selectedUserType: arg, redirect: true})   
    // }

    closeHistoryModel = () => {
        this.setState({ history_popup: false });
    }

    handleHistory = (id) => {
        this.setState({ history_popup: true });
        this.setState({ histoy_dealer_id: id });
    }

    render() {        
        const { dealerCountStatusDetail } = this.props;


        if (this.state.redirect) {
            return (<Redirect to={'/dealer/basic-details'} />);
        }
        return (
            <div className="result-wrap">
                <div className="card">
                    <div className="pad-15">
                        <div className="row">
                            <div className="col-sm-8">
                                <ul className="list-inline nav-tabs">
                                    <li className={this.state.statusClassname.active} onClick={this.handleStatusClick.bind(this, 'active')}><div>{this.props.t('Listing.Search_Result.Active')}<span>({dealerCountStatusDetail.active})</span></div></li>
                                    <li className={this.state.statusClassname.inactive} onClick={this.handleStatusClick.bind(this, 'inactive')}><div>{this.props.t('Listing.Search_Result.Inactive')}<span>({dealerCountStatusDetail.inactive})</span></div></li>
                                    <li className={this.state.statusClassname.incomplete} onClick={this.handleStatusClick.bind(this, 'incomplete')}><div>{this.props.t('Listing.Search_Result.Incomplete')}<span>({dealerCountStatusDetail.incomplete})</span></div></li>
                                    <li className={this.state.statusClassname.blacklist} onClick={this.handleStatusClick.bind(this, 'blacklist')}><div>{this.props.t('Listing.Search_Result.Blacklist')}<span>({dealerCountStatusDetail.blacklist})</span></div></li>
                                    <li className={this.state.statusClassname.pending} onClick={this.handleStatusClick.bind(this, 'pending')}><div>{this.props.t('Listing.Search_Result.Pending')}<span>({dealerCountStatusDetail.pending})</span></div></li>
                                    <li className={this.state.statusClassname.all} onClick={this.handleStatusClick.bind(this, 'all')}><div>{this.props.t('Listing.Search_Result.All')}<span>({dealerCountStatusDetail.all})</span></div></li>
                                    <li className={this.state.statusClassname.reject} onClick={this.handleStatusClick.bind(this, 'reject')}><div>{this.props.t('Listing.Search_Result.Rejected')}<span>({dealerCountStatusDetail.reject})</span></div></li>
                                </ul>
                            </div>
                            <div className="col-sm-4 text-right pad-t10">
                                <button onClick={() => this.props.onExportData()} type="button" className="btn btn-link mrg-r30" title={this.props.t('Listing.Search_Result.Export_Data')}>{this.props.t('Listing.Search_Result.Export_Data')}</button>
                                <NavLink to="/dealer/basic-details" className="btn btn-success">{(ALLOW_AUCTION == 1) ? this.props.t('Listing.Search_Result.Add_Auction') : this.props.t('Listing.Search_Result.Add_Dealer')}</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>{this.props.t('Listing.Search_Result.GCD')}</th>
                                        <th>{this.props.t('Listing.Search_Result.Details')}</th>
                                        <th>{this.props.t('Listing.Search_Result.City')}</th>
                                        <th>{this.props.t('Listing.Search_Result.Subscribed_To')}</th>
                                        <th>{this.props.t('Listing.Search_Result.Expiring_On')}</th>
                                        <th>{this.props.t('Listing.Search_Result.Onboarded_through')}</th>
                                        <th>{this.props.t('Listing.Search_Result.Actions')}</th>
                                        <th>{this.props.t('dealerDetails.Basic_Details_master.view_history')}</th>

                                        {/* <th>Active</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.dealerListData.length == 0 ? <tr><td align="center" colSpan="7"><h6 className="text-center text-danger text-bold">{this.props.t('Listing.Search_Result.No_Record_Found')}</h6></td></tr> : null}
                                    {(this.state.dealerListData.length != 0) && this.state.dealerListData.map((dealerdata, k) =>
                                        <tr key={k}>
                                            <td>{dealerdata.gcd_code}
                                                {dealerdata.bm_info.name && dealerdata.bm_info.role_id ?
                                                    <div>
                                                        <div><b>{this.state.role_list[dealerdata.bm_info.role_id]} Name: </b><span title={dealerdata.bm_info.name}>{dealerdata.bm_info.name.substring(0, 20)}</span>{dealerdata.bm_info.name.length > 20 ? "..." : ''}</div>
                                                        <div><b>{this.state.role_list[dealerdata.bm_info.role_id]} Mobile: </b>{dealerdata.bm_info.mobile} </div>
                                                    </div>
                                                    : ''}
                                                {dealerdata.bm_info.immediate_manager && dealerdata.bm_info.immediate_manager.name &&  dealerdata.bm_info.immediate_manager.role_id?
                                                    <div>
                                                        <div><b>{this.state.role_list[dealerdata.bm_info.immediate_manager.role_id]} Name: </b><span title={dealerdata.bm_info.immediate_manager.name}>{dealerdata.bm_info.immediate_manager.name.substring(0, 20)}</span>{dealerdata.bm_info.immediate_manager.name.length > 20 ? "..." : ''}</div>
                                                        <div><b>{this.state.role_list[dealerdata.bm_info.immediate_manager.role_id]} Mobile: </b>{dealerdata.bm_info.immediate_manager.mobile} </div>
                                                    </div>
                                                    : ''}
                                            </td>
                                            <td>
                                            <div>{dealerdata.organization} {dealerdata?.is_dealer_subscribe_for_dif && dealerdata.is_dealer_subscribe_for_dif === "1" && <span className="badge-if-activated">{ "IF Activated"}</span>}</div> 
                                                <div>{dealerdata.dealership_email}</div>
                                                <div>{dealerdata.dealership_contact}</div>
                                                {/* <div><img src="https://dealeradmin.gaadi.com/admin/public/images/gaadi.png"/></div> */}
                                            </td>
                                            <td>{(dealerdata.cityname) ? dealerdata.cityname : null}</td>
                                            <td>
                                                {
                                                    dealerdata.skunamestr == null ? null :
                                                        dealerdata.skunamestr.split(',').map((skuname, k) =>
                                                            <span className="label green-status mrg-r5" key={k}>{skuname}</span>
                                                        )
                                                }
                                            </td>
                                            <td>
                                                {
                                                    dealerdata.validitytostr == null ? null :
                                                        dealerdata.validitytostr.split('~').map((validitystr, key) =>
                                                            <div className="f12 text-light" key={key}>{validitystr}</div>
                                                        )
                                                }
                                            </td>
                                            <td>
                                                {this?.props?.dealer_onboarding_type?.find(type => type.value === dealerdata?.onboarding_type)?.name || ""}
                                            </td>
                                            <td>
                                                <div className="btn-group btn-group-small mrg-r10">

                                                    <Link to={`/dealer/${dealerdata.dealer_id_hash}/basic-details`} className="btn btn-default"><i className="ic-createmode_editedit icons-normal"></i></Link>
                                                    <Link to={`/dealer/${dealerdata.dealer_id_hash}/subscription-details`} className="btn btn-default" title="Edit Subscription"><i className="ic-subscription icons-normal"></i></Link>
                                                    {
                                                        (dealerdata.status == '1' || dealerdata.status == '2') ?
                                                            <button type="button" className="btn btn-default" onClick={this.handleBlackList(k, dealerdata.dealer_id_hash, '5')} title="Mark as Blacklist"><i className="ic-peson-blacklist icons-normal"></i></button> : (dealerdata.status == '5') ?
                                                                <button type="button" className="btn btn-default" onClick={this.handleBlackList(k, dealerdata.dealer_id_hash, '1')} title="Mark as Whitelist"><i className="ic-peson-listed icons-normal"></i></button> : null
                                                    }

                                                    {
                                                        (dealerdata.status == '1' || dealerdata.status == '2') ? <>
                                                            <label className="switch-btn btn btn-link" htmlFor={"active" + dealerdata.dealer_id_hash} >
                                                                <input className="switch-btn" id={"active" + dealerdata.dealer_id_hash} value="open" name={"active" + dealerdata.dealer_id_hash} type="checkbox" onClick={this.handleChange(k, dealerdata.dealer_id_hash,dealerdata.status)} checked={(dealerdata.status == '1') ? true : false} />
                                                                <div className="slider round"></div>
                                                                <span className="switch-label"></span>
                                                                {this.props.t('Listing.Search_Result.Active')}
                                                            </label></>
                                                            : ""
                                                    }
                                                </div>

                                            </td>
                                            <td>
                                                <button className="btn nowrap" onClick={() => { this.handleHistory(dealerdata.id) }}>{this.props.t('dealerDetails.Basic_Details_master.view_history')}</button>
                                            </td>
                                        </tr>
                                    )}
                                    {
                                        (this.state.loading) ? <tr><td className="loading" colSpan="6"></td></tr> : null
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                {this.state.blackListPopup == false ? null :
                    <ModalPopup id="black_list" className="modal" title={(parseInt(this.state.blackListUpdateData.status) == 5) ? this.props.t('Listing.Search_Result.Black_List_Dealer') : this.props.t('Listing.Search_Result.White_List_Dealer')} modalClose={this.closeModal}>
                        <BlackListPopup modalClose={this.closeModal} blackListUpdateData={this.state.blackListUpdateData} updateDealerListData={this.updateDealerListData} currentIndex={this.state.currentIndex} current_org_name={this.state.current_org_name} />
                    </ModalPopup>
                }
                {this.state.history_popup === false ? null : <ModalPopup id="view_history" className="modal ViewHistory data-table" title={'View History'} modalClose={this.closeHistoryModel}>
                    {this.state.histoy_dealer_id !== 0 && <ViewHistory histoy_dealer_id={this.state.histoy_dealer_id} />}
                </ModalPopup>}
                {/* {this.state.auctionModal == false ? null : 
                    <ModalPopup id="auction_popup_list" className="modal" title={this.props.t('Listing.Search_Result.auction')} modalClose={this.closeModal}>
                        <AuctionListPopup modalClose={this.closeModal} actionUser={this.submitAuctionUserForm}/>
                    </ModalPopup>
                } */}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResult)));
