import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import MasterService from "../../../service/MasterService";

class ViewHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dealerUserMappings: [],
            onboardingHistory: [],
            activeTab: "mapping",
        };
    }

    handleTabClick(tabName) {
        this.setState({ activeTab: tabName });
    }

    componentDidMount() {
        this.getDealerUserMappings();
        this.getOnboardingHistory();
    }

    getDealerUserMappings() {
        this.setState({ loading: true });
        MasterService.post("/dealer/dealer/get_dealer_user_mappings", { dealer_id: this.props.histoy_dealer_id })
            .then((response) => {
                if (response?.status === 200) {
                    this.setState({ dealerUserMappings: response?.data?.data || [] });
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    getOnboardingHistory() {
        this.setState({ loading: true });
        MasterService.post("/dealer/dealer/get_onboarding_history", { dealer_id: this.props.histoy_dealer_id })
            .then((response) => {
                if (response?.status === 200) {
                    this.setState({ onboardingHistory: response?.data?.data || [] });
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    renderTabContent() {
        const { activeTab, dealerUserMappings, onboardingHistory } = this.state;
        switch (activeTab) {
            case "mapping":
                return <DealerUserMapping userMapping={dealerUserMappings} />;
            case "history":
                return <OnboardingHistory history={onboardingHistory} />;
            default:
                return <div>Selected tab content not available</div>;
        }
    }

    render() {
        const { loading, activeTab } = this.state;

        return (
            <div>
                <div className={loading ? "tab-list loading" : "tab-list"}>
                    <a className={`nav-link ${activeTab === "mapping" ? "active" : ""}`} onClick={(e) => this.handleTabClick("mapping", e)} href={() => false}>
                        Dealer User Mapping
                    </a>
                    <a className={`nav-link ${activeTab === "history" ? "active" : ""}`} onClick={(e) => this.handleTabClick("history", e)} href={() => false}>
                        Onboarding History
                    </a>
                </div>
                <div className="tab-content">{this.renderTabContent()}</div>
            </div>
        );
    }
}

const DealerUserMapping = ({ userMapping }) => {
    return (
        <table style={{ width: "100%", tableLayout: "fixed" }}>
            <thead>
                <tr>
                    <th>User ID</th>
                    <th>User Name</th>
                    <th>User Mobile</th>
                    <th>User Email</th>
                    <th>Manager ID</th>
                    <th>Manager Name</th>
                    <th>Manager Mobile</th>
                    <th>Manager Email</th>
                    <th>Created Date</th>
                </tr>
            </thead>
            <tbody>
                {userMapping?.length ? (
                    userMapping?.map((mapping, idx) => (
                        <tr key={idx}>
                            <td style={{ wordWrap: "break-word" }}>{mapping?.user_id || "-"}</td>
                            <td style={{ wordWrap: "break-word" }}>{mapping?.user_name || "-"}</td>
                            <td style={{ wordWrap: "break-word" }}>{mapping?.user_mobile || "-"}</td>
                            <td style={{ wordWrap: "break-word" }}>{mapping?.user_email || "-"}</td>
                            <td style={{ wordWrap: "break-word" }}>{mapping?.manager_id || "-"}</td>
                            <td style={{ wordWrap: "break-word" }}>{mapping?.manager_name || "-"}</td>
                            <td style={{ wordWrap: "break-word" }}>{mapping?.manager_mobile || "-"}</td>
                            <td style={{ wordWrap: "break-word" }}>{mapping?.manager_email || "-"}</td>
                            <td style={{ wordWrap: "break-word" }}>{mapping?.created_date || "-"}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td>No data found</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

const OnboardingHistory = ({ history }) => {
    return (
        <table style={{ width: "100%", tableLayout: "fixed" }}>
            <thead>
                <tr>
                    <th>Dealer ID</th>
                    <th>Onboarding Type</th>
                    <th>Onboarding Status</th>
                    <th>Dealer Status</th>
                    <th>Created By</th>
                    <th>Created By Type</th>
                    <th>Application Date</th>
                    <th>Created Date</th>
                </tr>
            </thead>
            <tbody>
                {history?.length ? (
                    history?.map((item) => {
                        return (
                            <tr>
                                <td style={{ wordWrap: "break-word" }}>{item.dealer_id}</td>
                                <td style={{ wordWrap: "break-word" }}>{item?.onboarding_type || "-"}</td>
                                <td style={{ wordWrap: "break-word" }}>{item?.dealer_onboarding_status || "-"}</td>
                                <td style={{ wordWrap: "break-word" }}>{item?.status || "-"}</td>
                                <td style={{ wordWrap: "break-word" }}>{item?.created_by || "-"}</td>
                                <td style={{ wordWrap: "break-word" }}>{item?.created_by_type || "-"}</td>
                                <td style={{ wordWrap: "break-word" }}>{item?.application_date || "-"}</td>
                                <td style={{ wordWrap: "break-word" }}>{item?.created_date || "-"}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td>No data found</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

const mapStateToProps = (state) => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
    };
};

export default withTranslation("dealers")(connect(mapStateToProps, mapDispatchToProps)(ViewHistory));
